import EditIcon from "@mui/icons-material/EditRounded";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Stack, TextField, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { createApiResponse, withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import AutocompleteCreatable from "../../../../../../shared/components/AutocompleteCreatable";
import DataLoadingFailed from "../../../../../../shared/components/DataLoadingFailed";
import SearchField from "../../../../../../shared/components/inputs/SearchField";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../../shared/logging";
import adminApi from "../../../../../api/adminApi";
import { Fundraising, FundraisingAccessItem } from "../../../../../api/types/fundraisingTypes";
import { useClientContext } from "../../../../../context/ClientContext";
import { pageRoutes } from "../../../../../routes";
import { SelectOption } from "../../../../common/filters/filterTypes";
import RecordCounter from "../../../../common/filters/RecordCounter";
import CreateFundDialog from "./CreateFundDialog";
import FundraisingAccessGrid from "./FundraisingAccessGrid";
import { applySearchFilter } from "./fundraisingAccessGridDataProvider";
import { useFundraisingDetailsPageContext } from "./FundraisingDetailsPageContext";

const updateFundraising = withErrorHandling(adminApi.updateFundraising);

const createSelectedOption = (fundraising: Fundraising) =>
  fundraising.fund ? { value: fundraising.fund.id, label: fundraising.fund.name } : null;

const FundraisingAccessConfig = () => {
  const { clientCode } = useClientContext();
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const { categories, isContentEditable, fundraising, fundOptions, onUpdated, onConfirmSave } =
    useFundraisingDetailsPageContext();

  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(createSelectedOption(fundraising));

  const [isSaving, setSaving] = useState(false);
  const [filteredRows, setFilteredRows] = useState<FundraisingAccessItem[]>([]);
  const [showCreateFundDialog, setShowCreateFundDialog] = useState(false);

  const getAccessRows = useCallback(
    () =>
      categories.length > 0 && selectedOption
        ? adminApi.getFundraisingAccess(selectedOption.value)
        : Promise.resolve(createApiResponse([])),
    [categories, selectedOption]
  );

  const [rows, fetchError, { isFetching }] = useFetch(getAccessRows, (data) => setFilteredRows(data));

  const handleSearch = (searchValue: string) => {
    if (rows !== undefined) {
      setFilteredRows(applySearchFilter(searchValue, rows));
    }
  };

  const save = async () => {
    setSaving(true);
    const [resp, error] = await updateFundraising(fundraising.id, { fundId: selectedOption?.value });
    setSaving(false);

    if (error) {
      logError(error, "[FundraisingAccessConfig]");
      sendNotificationError("Could not update fundraising");
    } else {
      sendNotification("Fundraising updated successfully");
      onUpdated(resp);
    }
  };

  const handleSave = () => onConfirmSave(save);

  const handleReset = () => {
    setSelectedOption(createSelectedOption(fundraising));
  };

  const handleSavedWithNewFund = (updatedFundraising: Fundraising) => {
    setShowCreateFundDialog(false);
    setSelectedOption(createSelectedOption(updatedFundraising));
    onUpdated(updatedFundraising);
  };

  const isEdited = selectedOption?.value !== fundraising.fund?.id;
  const isValid = fundraising.status === "Draft" || Boolean(selectedOption);
  const showValidationError = isEdited && !isValid;

  const fundAccessRoute = selectedOption
    ? `/${clientCode}/${pageRoutes.investorRelations}/${pageRoutes.funds}/${selectedOption.value}/communication-matrix`
    : "";

  return (
    <Stack spacing={2.5} flex={1}>
      {categories.length === 0 && (
        <Alert severity="error">Access assignment is not possible: no Fundraising access categories defined</Alert>
      )}

      <Box>
        <Stack py={2} spacing={0.5}>
          <Typography variant="subtitle1">Access</Typography>
          <Typography color="text.secondary">
            Choose an exising or create a new fund to manage access to this fundraising.
          </Typography>
        </Stack>

        <AutocompleteCreatable
          withLazyRendering
          noItemsText="No funds found"
          onCreateOption={() => setShowCreateFundDialog(true)}
          openOnFocus
          fullWidth
          disabled={!isContentEditable || categories.length === 0}
          options={fundOptions}
          isOptionEqualToValue={(opt, val) => opt.value === val.value}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Funds"
              error={showValidationError}
              helperText={showValidationError ? "Fund is required" : ""}
            />
          )}
          value={selectedOption}
          onChange={(_, newValue) => {
            if (newValue) {
              setSelectedOption(newValue);
            }
          }}
        />

        {isContentEditable && (
          <Stack direction="row" spacing={1} pt={2}>
            <LoadingButton variant="contained" loading={isSaving} onClick={handleSave} disabled={!isEdited || !isValid}>
              Save
            </LoadingButton>
            <Button variant="text" color="secondary" onClick={handleReset} disabled={!isEdited}>
              Cancel
            </Button>
          </Stack>
        )}
      </Box>

      <Box flex={1}>
        <Stack py={2} spacing={0.5}>
          <Typography variant="subtitle1">Investors/Contacts</Typography>
          <Typography color="text.secondary">
            The list of investor contacts who can access the fundraising is based on the selected funds, portal role,
            and document access category. This dynamic list updates with any changes in access permissions or contact
            roles.
          </Typography>
        </Stack>

        <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
          <RecordCounter records={filteredRows.length} total={(rows ?? []).length} hide={isFetching} />
          <Stack direction="row" spacing={1} alignItems="center">
            <SearchField onSearch={handleSearch} debounceTimeMs={300} disabled={isFetching} />
            <Button
              component={RouterLink}
              to={fundAccessRoute}
              disabled={!fundAccessRoute}
              target="_blank"
              rel="noopener noreferrer"
              variant="contained"
              startIcon={<EditIcon />}
            >
              Manage Access
            </Button>
          </Stack>
        </Box>
        {!fetchError && <FundraisingAccessGrid rows={filteredRows} isLoading={isFetching} />}
        {fetchError && <DataLoadingFailed bgColor="none" />}
      </Box>

      {showCreateFundDialog && (
        <CreateFundDialog onSubmit={handleSavedWithNewFund} onClose={() => setShowCreateFundDialog(false)} />
      )}
    </Stack>
  );
};

export default FundraisingAccessConfig;
